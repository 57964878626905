import React from "react";
import { Link } from "react-router-dom";

const Footer = ({
  toggleTheme,
  runCommand,
  isLoading,
  darkMode,
  isHomePage,
}) => (
  <div
    className={`footer ${
      darkMode ? "footer-dark-hover" : "footer-light-hover"
    }`}
    style={{
      backgroundColor: darkMode ? "black" : "blue",
      color: darkMode ? "green" : "white",
    }}
  >
    <span className="clickable footer-item" onClick={toggleTheme}>
      COLOR
    </span>
    <span>{" | "}</span>
    {isHomePage && (
      <>
        <span
          className="clickable footer-item"
          onClick={() => !isLoading && runCommand()}
        >
          AUTO
        </span>
        <span>{" | "}</span>
      </>
    )}
    <Link
      to={isHomePage ? "/list" : "/"}
      className="clickable footer-item custom-link"
    >
      {isHomePage ? "MY PROJECTS" : "BACK HOME"}
    </Link>
  </div>
);

export default Footer;
