import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { UserProvider } from "./UserContext"; // Import UserProvider
import Home from "./pages/Home";
import List from "./pages/List";
import "./Assets/Css/Common.css";

function App() {
  const [darkMode, setDarkMode] = useState(true);

  const toggleTheme = () => {
    setDarkMode((prevDarkMode) => !prevDarkMode);
  };

  return (
    <UserProvider>
      {" "}
      {/* Wrap your app with UserProvider */}
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <Home
                darkMode={darkMode}
                setDarkMode={setDarkMode}
                toggleTheme={toggleTheme}
              />
            }
          />
          <Route
            path="/list"
            element={
              <List
                darkMode={darkMode}
                setDarkMode={setDarkMode}
                toggleTheme={toggleTheme}
              />
            }
          />
        </Routes>
      </div>
    </UserProvider>
  );
}

export default App;
