import React from "react";
import { Link } from "react-router-dom";

const Header = ({ darkMode }) => (
  <div
    className="header"
    style={{
      backgroundColor: darkMode ? "black" : "blue",
      color: darkMode ? "green" : "white",
      fontWeight: darkMode ? "normal" : "bold",
    }}
  >
    <Link to="/" className={darkMode ? "sliding-logo" : "sliding-logo-light"}>
      Jason Lee
    </Link>
  </div>
);

export default Header;
