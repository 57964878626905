import React, { createContext, useState, useEffect } from "react";
import Cookies from "js-cookie";

export const UserContext = createContext();

const generateUniqueId = () => {
  return "uid_" + Math.random().toString(36).substr(2, 9);
};

export const UserProvider = ({ children }) => {
  const [userId, setUserId] = useState("");

  useEffect(() => {
    let uniqueUser = Cookies.get("uniqueUser");
    if (!uniqueUser) {
      uniqueUser = generateUniqueId();
      Cookies.set("uniqueUser", uniqueUser, { expires: 7 }); // Expires in 7 days
    }
    setUserId(uniqueUser);
  }, []);

  return (
    <UserContext.Provider value={{ userId }}>{children}</UserContext.Provider>
  );
};
