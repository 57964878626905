import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Modal from "react-modal";
import { Helmet } from "react-helmet";

const getImagePath = (name, fileName) => {
  return `${process.env.PUBLIC_URL}/screenshots/Projects/${name}/${fileName}`;
};

const getThumbnailPath = (name, fileName) => {
  return `${process.env.PUBLIC_URL}/thumbnails/Projects/${name}/${fileName}`;
};

const RenderDescription = ({ description }) => {
  const textWithBreaks = description.split("\n").map((text, index, array) =>
    array.length - 1 === index ? (
      text
    ) : (
      <>
        {text}
        <br />
      </>
    )
  );

  return <>{textWithBreaks}</>;
};

const projects = [
  {
    name: "Ultraviolet Chat (2022-2023)",
    folder: "uv_chat",
    description:
      "Ultraviolet is a community chat platform built with Microservices, React Native, DynamoDB, and Lambda.\n\nDesigned to bridge the gap between creators and their followers—transforming engagement into meaningful support.",
    screenshots: ["blog_graphic_2.jpg", "blog_graphic_3.jpg"],
    relatedLinks: [
      {
        url: "https://apps.apple.com/us/app/ultraviolet-chat/id6444900158",
        description: "Ultraviolet Chat Donwload",
      },
      { url: "https://www.ultraviolet.club/", description: "Official Site" },
    ],
  },
  {
    name: "Octi App (2021-2022)",
    folder: "octi_app",
    description:
      "Octi is a social platform developed using TypeScript, Websocket API, RDS, Lambda, and Swift, allowing users to create digital worlds and videos while earning coins. The platform draws functional inspiration from established apps like TikTok and Snapchat, aiming to explore the intersection between physical and digital social interactions.",
    screenshots: [
      "FEWWjN6UcAAlriP.jpg",
      "IMG_0014.PNG",
      "IMG_0018.PNG",
      "IMG_0019.PNG",
      "IMG_0022.PNG",
      "IMG_0023.PNG",
      "IMG_0024.PNG",
      "IMG_0026.PNG",
      "IMG_0027.PNG",
    ],
    relatedLinks: [
      {
        url: "https://www.youtube.com/watch?v=C_KiyitdmH0",
        description: "News - Youtube",
      },
      {
        url: "https://youtu.be/laL5QJK1gDI?si=rTti-niUTGdUiWwO",
        description: "Demo - Youtube",
      },
    ],
  },
  {
    name: "Guideness.co (2021)",
    folder: "guideness",
    description:
      "Developed during my time in Digital Media Management, Guideness is a web-based wellness platform engineered to bridge the gap between users and specialized Guides in areas like fitness, nutrition, and self-improvement. Leveraging user-centric design, the platform provides exclusive content and community engagement, serving as a comprehensive solution for well-being improvement.",

    screenshots: ["site.png"],
    relatedLinks: [
      {
        url: "https://www.https://guideness.co/",
        description: "Official Site",
      },
    ],
  },
  {
    name: "5th kind LLC (2019-2020)",
    folder: "5thkind",
    description:
      "As a Sr. Web developer at 5th Kind LLC, I contributed to a team responsible for creating a Digital Asset Management (DAM) system that serves major film studios like Marvel, Disney, Illumination, NBCU, and WB. Utilizing technologies like IBM Aspera, AWS, Docker, Jenkins, New Relic, and RabbitMQ, the platform specializes in real-time collaboration and asset management, offering a centralized and industry-leading solution for creative production",

    screenshots: [
      "works.jpg",
      "cancel-package-from-viewer-10.png",
      "mobileapp-compare-2.png",
      "mobileapp-compare-3.png",
      "mobileapp-compare-4.png",
      "View-Package-Group-30.png",
      "32.png",
    ],
    relatedLinks: [
      {
        url: "https://www.hollywoodreporter.com/movies/movie-news/sohonet-acquisition-5th-kind-remote-collaboration-1235357313/",
        description: "News - Sohonet Acquisition of 5th Kind",
      },
      {
        url: "https://www.linkedin.com/products/pacgenesis-5th-kind/",
        description: "Linkedin",
      },
    ],
  },
  {
    name: "Innodt (2019)",
    folder: "innodt",
    description:
      "As a Sr. Software Engineer at InnoDT, I developed API tools with MongoDB and Lumen to specialize in real-time blockchain analytics and transactional insights across multiple blockchains. The platform leverages big data technologies and offers a highly scalable API capable of handling tens of thousands of queries per second. This empowers users and clients alike to focus on building algorithms and applications for diverse use-cases, all while ensuring data accuracy and reliability",

    screenshots: ["access_real_time_apis.png"],
    relatedLinks: [
      {
        url: "https://innodt.com/",
        description: "Official Site",
      },
    ],
  },
  {
    name: "creditassist.com (2015-2018)",
    folder: "visionstar",
    description:
      "As a Sr. Software Engineer at CreditAssist.com, I played a key role in fulfilling the company's objective to make credit reporting more accurate and user-friendly. I was responsible for developing a monthly subscription-based score reporting solution and a comprehensive CRM system. This included a collaboration with TransUnion to integrate their service API, ensuring that all PCI compliance and architectural requirements were met. I also developed a sales tool used by telemarketers to pull complete credit reports and FICO scores, which provided actionable insights for transactional issues. In addition, I designed and developed a queue system that streamlined the process of printing dispute letters in an organized manner. My contributions helped in the mission of navigating consumers through the often complex world of credit reporting, setting the stage for a more transparent and efficient experience",

    screenshots: [
      "p1.png",
      "p2.png",
      "p3.png",
      "p4.png",
      "p5.png",
      "p6.png",
      "p7.png",
      "p8.png",
      "p9.png",
      "ss1.jpg",
      "ss10.jpg",
      "ss11.png",
      "ss12-1.png",
      "ss12-2.jpg",
      "ss12-3.png",
      "ss13-1.png",
      "ss13-2.png",
      "ss13-3.png",
      "ss14.png",
      "ss15.png",
      "ss16.png",
      "ss2.jpg",
      "ss3.jpg",
      "ss4.png",
      "ss5.jpg",
      "ss6.jpg",
      "ss7.png",
      "ss8.png",
      "ss9.jpg",
    ],

    relatedLinks: [
      {
        url: "https://creditassist.com/",
        description: "Official Site",
      },
    ],
  },
  {
    name: "Kulimedia LLC (2015)",
    folder: "kulimedia",
    description:
      "As a key member of the engineering team at KuliMedia LLC, my role centered around scaling and maintaining the backend infrastructure to handle high-traffic loads, specifically more than 1 million unique impressions per day per landing page. I leveraged AWS services to set up and maintain a variety of servers, ensuring reliability and performance. My responsibilities also included implementing platforms for both advertisers and publishers, which allowed streamlined management of content and facilitated timely payouts. These efforts were critical in providing a robust and efficient system capable of handling significant volumes of data and transactions.",

    screenshots: ["kulimedia.png", "adsnative.JPG"],
    relatedLinks: [{}],
  },
  {
    name: "BGP LLC (2014)",
    folder: "begreat",
    description:
      "At BGP, I developed a CMS for our co-working spaces, leveraging WebSockets for real-time interaction between users across different rooms and other co-working locations.",
    screenshots: [
      "Home-Dashboard-completed.jpg",
      "dashboard-labs_my-subscription-2.jpg",
      "Home-Dashboard.jpg",
      "dashboard-labs_my-subscription.jpg",
      "Setting-1.jpg",
      "event.jpg",
      "Setting-2.png",
      "other-profile.jpg",
      "dashboard-labs.jpg",
      "post.png",
    ],
    relatedLinks: [{}],
  },
  {
    name: "Kively (2013)",
    folder: "kively",
    description:
      "In the Kively project, I was responsible for the end-to-end architecture, design, and development of a subscription-based online bank and credit card transaction monitoring system. Integrated with Scambook's database, the system effectively identifies fraudulent and miscellaneous charges, covering both backend and frontend components.",
    screenshots: [
      "admin_interface.JPG",
      "admin_interface2.JPG",
      "admin_interface3.JPG",
      "admin_interface4.JPG",
      "admin_interface5.JPG",
      "admin_interface6.JPG",
      "admin_interface7.JPG",
      "kively1.png",
      "software architecture.jpg",
      "user_interface1.JPG",
      "user_interface2.JPG",
      "user_interface3.JPG",
      "user_interface4.JPG",
      "user_interface5.JPG",
      "user_interface6.JPG",
    ],
    relatedLinks: [{}],
  },
  {
    name: "Banyak (2012)",
    folder: "banyak",
    description:
      "In my role at Banyak, a video sharing site that ranked within the top 1000 websites in Indonesia, I was responsible for designing and developing the entire architecture. Utilizing AWS S3, the platform managed an impressive 30TB of data transfer per week for uploading and downloading files.",
    screenshots: [
      "dashboard1.JPG",
      "dashboard2.JPG",
      "screenshot_main.JPG",
      "screenshot_main2.JPG",
      "screenshot_main3.JPG",
    ],
    relatedLinks: [{}],
  },
  {
    name: "Scambook.com (2011)",
    folder: "scambook",
    description:
      "At Scambook, I migrated to Solr for improved search, designed solutions on an HMVC PHP framework, and implemented a recurring payments system. These enhancements supported both consumer complaints and business reputations.",
    screenshots: ["old.png"],
    relatedLinks: [
      {
        url: "https://www.scambook.com/",
        description: "Official Site",
      },
    ],
  },
  {
    name: "LuxuryLink.com (2010)",
    folder: "luxurylink",
    description:
      "Luxury Link serves as an online hub for luxury travel experiences, linking travelers to high-end properties globally. As a Software Developer, I migrated our community forum to vBulletin, reducing monthly costs by $12,000, and implemented SOAP-based Extranet and data feed systems to bolster business operations.",
    screenshots: ["captured.png"],
    relatedLinks: [
      {
        url: "https://luxurylink.com/",
        description: "Official Site",
      },
    ],
  },
  {
    name: "iChange (2010)",
    folder: "ichange",
    description:
      "At iChange, a company later acquired by Herbalife International, I was one of the founding developers for a mobile-friendly social networking platform aimed at weight loss and wellness. Developed on the LAMP stack using Symfony 1.1 framework, the platform adhered to OOP and MVC architectures. It featured a responsive interface built with AJAX, JSON, and Javascript, alongside automated email and file-cleaning utilities.",
    screenshots: ["302528190_414419437454117_7203120797933184128_n.jpg"],
    relatedLinks: [
      {
        url: "https://vator.tv/news/2011-04-24-ichange-a-healthy-acquisition-for-herbalife",
        description: "iChange acquired by Herbalife",
      },
      {
        url: "https://www.crunchbase.com/organization/ichange",
        description: "crunchbase",
      },
    ],
  },
];

const RenderProject = ({ project, expanded, toggle, darkMode }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  return (
    <>
      <Helmet>
        <title>Jason Lee - Software Developer Portfolio Project List</title>
        <meta
          name="description"
          content="Explore Jason Lee's portfolio, a highly skilled software developer specialized in React and other modern web technologies. Get insights into projects, skills, and career highlights."
        />
        <meta
          name="keywords"
          content="Jason Lee, Software Developer, React Developer, Web Developer, Portfolio, Coding, Programming"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
        <meta name="author" content="Jason Lee" />
      </Helmet>
      <div>
        <h2
          onClick={() => toggle(project)}
          className={darkMode ? "project-title-dark" : "project-title-light"}
        >
          {project.name}
          <div className={darkMode ? "divider-dark" : "divider-light"}></div>
        </h2>
        {expanded && (
          <div className="project-details">
            <p>
              <RenderDescription description={project.description} />
            </p>
            <div
              style={{ display: "flex", flexWrap: "wrap", maxWidth: "100%" }}
            >
              {project.screenshots &&
                project.screenshots.map((fileName, index) => (
                  <img
                    key={index}
                    src={getThumbnailPath(
                      project.folder.split(" ").join(""),
                      fileName
                    )}
                    style={{
                      width: "auto",
                      height: "200px",
                      paddingRight: "10px",
                      paddingBottom: "10px",
                    }}
                    alt={`Screenshot of ${project.name}`}
                    onClick={() => {
                      setCurrentImage(
                        getImagePath(
                          project.folder.split(" ").join(""),
                          fileName
                        )
                      );
                      setModalOpen(true);
                    }}
                  />
                ))}
            </div>
            <br />
            {project.relatedLinks && (
              <div>
                {project.relatedLinks.map((link, index) => (
                  <div key={index} style={{ marginBottom: "10px" }}>
                    <a
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={
                        darkMode ? "youtube-link-dark" : "youtube-link-light"
                      }
                    >
                      {link.description}
                    </a>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setModalOpen(false)}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.85)", // Darken the overlay background
            },
            content: {
              position: "relative", // Make the content a relative container
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "black", // Set content background to black
              padding: 0, // Remove padding to reduce unnecessary space
              border: "none", // Remove border to focus on the image
              borderRadius: "8px", // Optional: Add a slight border radius for smooth corners
              maxWidth: "90vw", // Limit modal width to avoid overly large modals
              maxHeight: "90vh", // Limit modal height
              overflow: "hidden", // Remove scrollbars within the modal content
            },
          }}
          onAfterOpen={() => {
            document.body.style.overflow = "hidden"; // Prevent body from scrolling when modal is open
          }}
          onAfterClose={() => {
            document.body.style.overflow = "auto"; // Re-enable body scrolling when modal is closed
          }}
        >
          <div style={{ position: "relative" }}>
            <button
              onClick={() => setModalOpen(false)}
              style={{
                position: "absolute", // Position absolutely within the image container
                top: "5px", // 5px from the top of the image
                right: "5px", // 5px from the right of the image
                background: "rgba(0, 0, 0, 0.5)", // Semi-transparent background to make it visible over the image
                border: "none",
                color: "white", // Ensure the close button is visible against the image
                fontSize: "2.5em", // Make the button larger
                cursor: "pointer",
                padding: "0 10px", // Add some padding for a larger clickable area
                borderRadius: "50%", // Make it circular
              }}
            >
              &times;
            </button>
            <img
              src={currentImage}
              alt="Enlarged"
              onClick={() => setModalOpen(false)}
              style={{
                maxHeight: "90vh",
                maxWidth: "90vw",
                objectFit: "contain", // Ensures the image is fully visible without distortion
                backgroundColor: "black", // Match image background to modal
              }}
            />
          </div>
        </Modal>
      </div>
    </>
  );
};

const List = ({ darkMode, toggleTheme }) => {
  const [expandedProjects, setExpandedProjects] = useState({});
  const toggleProject = (project) => {
    setExpandedProjects({
      ...expandedProjects,
      [project.name]: !expandedProjects[project.name],
    });
  };

  const listContentClass = darkMode
    ? "list-content"
    : "list-content light-mode";

  return (
    <div className={listContentClass}>
      <Header darkMode={darkMode} />
      <div className="scrollable-list-content">
        {projects.map((project, index) => (
          <RenderProject
            key={index}
            project={project}
            index={index}
            expanded={expandedProjects[project.name]}
            toggle={toggleProject}
            darkMode={darkMode}
          />
        ))}
      </div>
      <Footer
        toggleTheme={toggleTheme}
        runCommand={() => {}}
        isLoading={false}
        darkMode={darkMode}
        isHomePage={false}
      />
    </div>
  );
};

export default List;
