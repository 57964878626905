import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import Constants from "./../Const";
import Header from "./Header";
import Footer from "./Footer";
import { useCallback } from "react";
import { UserContext } from "../UserContext";

const Home = ({ darkMode, setDarkMode }) => {
  const EXAMPLE_PROMPT = "SUMMARIZE ABOUT JASON LEE AND HIS EXPERIENCE";

  const EXAMPLE_PROMPT_RESPONSE =
    "Jason Lee is a seasoned Fullstack Software Engineer based in Los Angeles, CA, with over 15 years of experience specializing in both monolithic and microservices architectures. He is proficient in a variety of programming languages and paradigms, including but not limited to Node.js, TypeScript, and React.js, and has a deep understanding of relational and NoSQL databases like PostgreSQL, MySQL, MongoDB, and DynamoDB. An expert in AWS services and various DevOps tools, Jason aims to create innovative web services, foster positive team relationships, and continually challenge his own skill sets. He holds a B.S. in Computer Science from Missouri University of Science and Technology and has an extensive employment history with multiple companies across different domains. Outside of work, Jason is a family man with diverse hobbies, including playing bass guitar and chess, among other intellectual and athletic pursuits.";

  const [input, setInput] = useState("");
  const inputRef = useRef(null);
  const [history, setHistory] = useState([]);
  const historyRef = useRef(null);
  const [maxHeight] = useState("calc(100vh - 80px)");
  const [inputWidth, setInputWidth] = useState(0);
  const [parentWidth, setParentWidth] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // New state variable
  const wrapperRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const { userId } = useContext(UserContext);

  const themeStyle = {
    backgroundColor: darkMode ? "black" : "blue",
    color: darkMode ? "green" : "white",
  };

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        // Display popup instead of console log
        setPopupMessage("Text successfully copied");
        setShowPopup(true);

        // Hide the popup after 2 seconds
        setTimeout(() => setShowPopup(false), 2000);
      },
      (err) => {
        // Display popup instead of console error
        setPopupMessage("Could not copy text");
        setShowPopup(true);

        // Hide the popup after 2 seconds
        setTimeout(() => setShowPopup(false), 2000);
      }
    );
  };

  const runCommand = async () => {
    if (isLoading) return;

    // Set the new input value
    setInput(EXAMPLE_PROMPT);
  };

  const toggleThemeInHome = () => {
    setDarkMode((prevDarkMode) => {
      if (prevDarkMode) {
        document.documentElement.style.setProperty(
          "--bright-response-color",
          "#ffffff"
        );
      } else {
        document.documentElement.style.setProperty(
          "--bright-response-color",
          "#00ff00"
        );
      }
      focusInput(); // refocus the input
      return !prevDarkMode; // toggle the dark mode
    });
  };

  const handleKeyDown = useCallback(
    async (e) => {
      const enterKey = e && e.key === "Enter";
      const isMockEvent = e === null;
      const shouldProcess = input.trim().length > 0 && !isLoading;

      const apiPost = async (url, data) => {
        try {
          const response = await axios.post(url, data);
          console.log("Server response:", response);
          return response.data;
        } catch (error) {
          console.error("There was an error sending the request", error);
          throw error;
        }
      };

      // mock for testing
      // const apiPost = async (url, data) => {
      //   return new Promise((resolve, reject) => {
      //     setTimeout(() => {
      //       resolve({
      //         output: "This is a mock response for input: " + data.input,
      //       });
      //     }, 1000); // 1 second delay to simulate network latency
      //   });
      // };

      const appendToHistory = (newItem, isBright = false) => {
        setHistory((prevHistory) => {
          return [...prevHistory, { content: newItem, isBright }];
        });
      };

      if ((enterKey && shouldProcess) || isMockEvent) {
        console.log("Processing input...");

        if (e && e.preventDefault) {
          e.preventDefault();
        }

        appendToHistory(input); // Append the typed input to the history

        if (input.trim().toUpperCase() === "/HELP") {
          appendToHistory(
            <span>
              WELCOME TO MY PROFESSIONAL PORTFOLIO. IF YOU HAVE ANY INQUIRIES
              REGARDING MY EXPERIENCE, SKILL SET, OR PROJECTS, FEEL FREE TO ASK.
              A SOPHISTICATED AI IS AT YOUR SERVICE TO PROVIDE COMPREHENSIVE
              RESPONSES. THANK YOU FOR YOUR TIME AND CONSIDERATION.
              <br />
              <br />
              - JASON LEE
              <br />
              <br />
              <span
                onClick={() => copyToClipboard("THEMANLEE7942+10@GMAIL.COM")}
                style={{ cursor: "pointer", textDecoration: "none" }}
              >
                COPY EMAIL TO CLIPBOARD: THEMANLEE7942+10@GMAIL.COM
              </span>
              <br />
              <br />
              TYPE YOUR QUESTIONS BELOW
            </span>
          );
        } else if (input.trim().toUpperCase() === EXAMPLE_PROMPT) {
          appendToHistory(EXAMPLE_PROMPT_RESPONSE);
        } else {
          setIsLoading(true); // Show loading message
          appendToHistory("Generating response...", true); // Add loading message

          try {
            const response = await apiPost(`${Constants.API_URL}/ai`, {
              input,
              userId,
            });

            // Remove the loading message from the history
            setHistory((prevHistory) => prevHistory.slice(0, -1));

            if (response && response.output) {
              appendToHistory(response.output, true); // Append server response to history
            }
          } catch (error) {
            console.error("Error while communicating with the backend:", error);
            // Remove the loading message from the history
            setHistory((prevHistory) => prevHistory.slice(0, -1));
            appendToHistory("There was an error", true); // Add error message and make it bright
          }

          setIsLoading(false); // Remove loading message
        }

        setInput("");
        const newWidth = getTextWidth("", "20px Courier New, monospace");
        setInputWidth(newWidth); // Reset the input width
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }
    },
    [input, isLoading, setIsLoading, inputRef]
  );

  useEffect(() => {
    if (input === EXAMPLE_PROMPT) {
      handleKeyDown(null);
    }
  }, [input]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (historyRef.current) {
      historyRef.current.scrollTop = historyRef.current.scrollHeight;
      historyRef.current.style.overflowY = "auto";
    }
  }, [history]);

  const handleScroll = (e) => {
    if (historyRef.current) {
      historyRef.current.scrollTop += e.deltaY;
    }
  };

  const getTextWidth = (text, font) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  };

  useEffect(() => {
    if (inputRef.current) {
      const width = getTextWidth(input, "20px Courier New, monospace");
      setInputWidth(width);
    }
  }, [input]);

  useEffect(() => {
    if (wrapperRef.current) {
      const parentWidth = wrapperRef.current.offsetWidth;
      setParentWidth(parentWidth);
    }
  }, []);

  useEffect(() => {
    if (!isLoading && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isLoading]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--bright-response-color",
      darkMode ? "#00ff00" : "#ffffff"
    );
  }, [darkMode]);

  return (
    <>
      {!darkMode && (
        <style>{`
          .input-wrapper span::before {
            background-color: white !important;
        }`}</style>
      )}

      <Helmet>
        <title>Jason Lee - Software Developer Portfolio AI</title>
        <meta
          name="description"
          content="Explore Jason Lee's portfolio, a highly skilled software developer specialized in React and other modern web technologies. Get insights into projects, skills, and career highlights."
        />
        <meta
          name="keywords"
          content="Jason Lee, Software Developer, React Developer, Web Developer, Portfolio, Coding, Programming"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
        <meta name="author" content="Jason Lee" />
      </Helmet>

      <div className="container" onWheel={handleScroll} style={themeStyle}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            backgroundColor: darkMode ? "black" : "blue",
            color: darkMode ? "green" : "white",
          }}
        >
          {/* Header */}
          <Header darkMode={darkMode} />

          {/* Content */}
          <div
            className="content"
            style={{ ...themeStyle, maxHeight: maxHeight }}
            ref={historyRef}
            onClick={focusInput}
          >
            <div className="scrollable-content">
              <div>
                MSX2 BASIC version 3.0
                <br />
                Powered by AI
                <br />
                Ok
                <br />
                <span>10 COLOR 2,0,0</span>
                <br />
                <span>20 PRINT "TYPE '/HELP' FOR MORE INFO"</span>
                <br />
                <span>30 END</span>
                <br />
                <br />
                <span>run</span>
                <br />
                <span>TYPE '/HELP' FOR MORE INFO</span>
                <br />
                <span>Ok</span>
                <br />
                <br />
              </div>

              {history.map((item, index) => (
                <div
                  key={index}
                  className={`${
                    darkMode
                      ? index % 2 === 0
                        ? "custom-input"
                        : "bright-response"
                      : index % 2 !== 0
                      ? "white-bold"
                      : ""
                  } ${item.isBright ? "bright-response" : ""}`}
                >
                  {typeof item.content === "string"
                    ? `> ${item.content}`.split("\n").map((line, i) => (
                        <React.Fragment key={i}>
                          {i > 0 && <br />}
                          {line}
                        </React.Fragment>
                      ))
                    : item.content}
                </div>
              ))}
            </div>

            <div className="input-wrapper" ref={wrapperRef}>
              <div
                className="styled-input"
                style={{
                  backgroundColor: darkMode ? "transparent" : "blue",
                }}
              >
                <div className="input-container">
                  <input
                    type="text"
                    ref={inputRef}
                    value={isLoading ? "" : input}
                    disabled={isLoading}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                    className="actual-input"
                    style={{
                      caretColor: "transparent",
                      backgroundColor: darkMode ? "transparent" : "blue",
                      color: darkMode ? "green" : "white",
                      width: `${Math.max(inputWidth, parentWidth - 10)}px`,
                    }}
                  />
                </div>
                <span
                  style={{
                    position: "absolute",
                    left: `${inputWidth}px`,
                    backgroundColor: darkMode ? "green" : "white",
                  }}
                  className={`caret ${isLoading ? "hide-element" : ""}`}
                ></span>
              </div>
            </div>
          </div>

          {/* Footer */}
          <Footer
            toggleTheme={toggleThemeInHome}
            runCommand={runCommand}
            isLoading={isLoading}
            darkMode={darkMode}
            isHomePage={true}
          />
        </div>
        {showPopup && <div className="popup">{popupMessage}</div>}
      </div>
    </>
  );
};

export default Home;
